import {makeStyles} from "@material-ui/core";
import logo from "../../../icons/b2c-logo.svg";
import React from "react";
import {HeaderProfile} from "./HeaderProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 65,
    background: '#fff',
    zIndex: 1300,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backgroundRoot: {
    height: 65,
    left: 0,
    right: 0,
    top: 0,
    background: '#fff',
    position: 'fixed',
  },
  container: {
    margin: "auto",
    width: '100%',
    maxWidth: "680px",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: "20%",
    '@media screen and (max-width:1060px)': {
      width: 'auto'
    },
    '@media screen and (max-width:680px)': {
      paddingLeft: "12px",
    }
  },
}));

export const ClientHeader = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <a href={`/`}>
            <img src={logo} alt="b2c" />
          </a>
        </div>
        <HeaderProfile/>
      </div>
      {/*<div className={classes.container}>*/}
      {/*  */}
      {/*</div>*/}
    </div>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import {ClientsDashboardApp} from './ClientsDashboardApp';
import '../importWebFonts';
import '../i18n';

 ReactDOM.render(
  <ClientsDashboardApp/>,
   document.getElementById('root')
 );

/* export const unmountComponentAtNode = ReactDOM.unmountComponentAtNode;

export default (element: ReactDOM.Container) => {
  ReactDOM.render(
    <ClientsDashboardApp/>,
    element
  );
}
 */
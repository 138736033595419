import {combineReducers, configureStore, Reducer} from '@reduxjs/toolkit';
import authReducer from './auth/authStore';
import logger from 'redux-logger';
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {profileReducer} from "./profile/profileStore";

const combinedReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer
});

type RootStateExtract<R> = R extends Reducer<infer State> ? State : never;
export type RootState = RootStateExtract<typeof combinedReducer>;

const rootReducer: Reducer<RootState> = (state, action) => {
  if (action.type === 'logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => process.env.NODE_ENV === 'development'
    ? getDefaultMiddleware().concat(logger)
    : getDefaultMiddleware()
});

export type AppDispatch = typeof store.dispatch;

export default store;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();

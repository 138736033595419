import React, {useEffect} from 'react';
import {ThemeProvider, CssBaseline} from '@material-ui/core';
import {b2cTheme, useGlobalStyles} from "../theme/B2CTheme";
import '../utils/validation';
import {enableMapSet} from "immer";
import {IsMobileContext, useIsMobileContextValue} from '../hooks/useIsMobile';
import store, {useAppDispatch, useAppSelector} from "./store";
import {changeUserToken, selectAuthState, selectUserProfile} from './store/auth/authStore';
import {Provider} from "react-redux";
import {setToken} from "../api";
import {fetchClientProfile} from "./store/auth/profileLoad";
import {AuthWizard} from "../masters/pages/Auth";
import {ClientProfileContext} from "./store/auth/profileContext";
import {BrowserRouter} from "react-router-dom";
import {ClientHeader} from "./components/ClientHeader/ClientHeader";

const ClientsDashboardRouter = React.lazy(async () => (await import('./navigation/ClientsDashboardRouter')));

enableMapSet();

const RouterOnAuth = () => {
  const authState = useAppSelector(selectAuthState);
  const clientProfile = useAppSelector(selectUserProfile);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (authState.token) dispatch(fetchClientProfile());
  }, [authState.token, dispatch]);

  if (authState.loading) {
    return <div/>;
  }

  return (
    <>
      {!!clientProfile && (
        <React.Suspense fallback={<div />}>
          <ClientProfileContext.Provider value={clientProfile}>
            <ClientsDashboardRouter/>
          </ClientProfileContext.Provider>
        </React.Suspense>
      )}
      {!clientProfile && (
        <div>
          <ClientHeader/>
          <React.Suspense fallback={<div />}>
            <AuthWizard
              role={'customer'}
              onUserAuth={(token, profile) => {
                localStorage.setItem('token', token);
                setToken(token);
                dispatch(changeUserToken(token));
                dispatch(fetchClientProfile());
              }}
            />
          </React.Suspense>
        </div>
      )}
    </>
  );
}

interface ClientsDashboardAppProps {
  browserRouterProps?: React.ComponentProps<typeof BrowserRouter>,
}

export const ClientsDashboardApp = (props: ClientsDashboardAppProps) => {
  const isMobileContextValue = useIsMobileContextValue();
  useGlobalStyles();

  return (
    <React.Suspense fallback={<div />}>
      <ThemeProvider theme={b2cTheme}>
        <CssBaseline />
        <Provider store={store}>
          <IsMobileContext.Provider value={isMobileContextValue}>
            <RouterOnAuth/>
          </IsMobileContext.Provider>
        </Provider>
      </ThemeProvider>
    </React.Suspense>
  );
}
